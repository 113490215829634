
import filters from "~/mixins/filters";

export default {
  mixins: [filters],

  props: {
    offer: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    priceDifference() { return this.offer.price_old - this.offer.price },
    creditDiscount() { return Math.max(0, this.priceDifference * 0.7) },
    tradeInDiscount() { return Math.max(0, this.priceDifference * 0.3) },
    maxDiscount() { return Math.max(0, this.priceDifference) },
  },
  methods: {
    async creditClick() {
      this.$router.push({
				path: '/credit', query: {
					mark: this.offer.mark.slug,
					model: this.offer.folder.slug,
					car: this.offer.external_id,
					fromCard: true
				},
			})
    },
  },
};
